import { useEffect, useState } from 'react';
import { CarSvg } from './CarSvg';
import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import { formatString } from '../../utils';
import { svgConfigDefaultUrlHash, svgDataHash } from '../../constants/svgHash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Car = ({
  onValidate,
  repairTexts,
  setRepairTexts,
  carRepairValues,
  setCarRepairValues,
  totalCarRepairValue,
  setTotalCarRepairValue,
}) => {
  const [invalidIds, setInvalidIds] = useState([]);
  const colors = ['white', '#FFEECD', '#F9c07a', '#f97779', '#979ed2'];
  const colorsForRequired = ['white', 'green', '#F9c07a', '#f97779', '#979ed2'];
  const requiredIds = [
    'pneu_ard',
    'pneu_arg',
    'pneu_avg',
    'pneu_avd',
    'optique_avg',
    'optique_avd',
    'optique_ard',
    'optique_arg',
    'pare_brise_av',
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const ajSvgClick = (id: string, onNewRepairText: string) => {
    let urlString = [];

    if (svgDataHash[id] && svgDataHash[id]['params']) {
      for (const k in svgDataHash[id]['params']) {
        let v = '';
        if (k === 'index') {
          let element = document.getElementById(id);
          if (element && element.value && element.value.includes('__--__')) {
            v = `${k}=${element.value.split('__--__')[1]}`;
          }
        }

        if (v === '') {
          v = `${k}=${svgDataHash[id]['params'][k]}`;
        }

        urlString.push(v);
      }
    } else {
      console.error("svgDataHash[id] ou svgDataHash[id]['params'] est undefined");
    }

    if (Object.keys(svgConfigDefaultUrlHash)?.length > 0) {
      for (let k in svgConfigDefaultUrlHash) {
        urlString.push(`${k}=${svgConfigDefaultUrlHash[k]}`);
      }
    }

    urlString.push('fds=mRMvHRpsA1tqBM6y9PYv1HnpHoOkEOYlcXGXxYYfuf2I9TJGN9');

    if (urlString?.length > 0) {
      urlString = urlString.join('&');

      fetch(svgDataHash[id]['post_url'], {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlString,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && data.response && data.response.valid === 'true') {
            if (data.response.svg_val !== '') {
              const d_arr = data.response.svg_val.split('__--__');
              const element = document.getElementById(d_arr[0]);

              const elementsToFill = element?.querySelectorAll('path, rect, ellipse, circle, polygon, polyline');
              elementsToFill?.forEach((elementsToFill) => {
                if (elementsToFill.style) {
                  // Check if the id of the element is in requiredIds
                  if (requiredIds.includes(d_arr[0])) {
                    // Use colorsForRequired if it is a required element
                    elementsToFill.style.fill = colorsForRequired[d_arr[2]];
                  } else {
                    // Use colors otherwise
                    elementsToFill.style.fill = colors[d_arr[2]];
                  }

                  // Réinitialiser le stroke de l'élément ici
                  if (colors[d_arr[2]] !== 'white') {
                    elementsToFill.style.stroke = '#515151';
                  }
                }
              });

              if (d_arr[1] == 'null') {
                element.value = '';
                onNewRepairText(id, '');

                // Si un élément a une valeur nulle, le supprimer de carRepairValues
                setCarRepairValues((prevValues) => {
                  const updatedValues = { ...prevValues };
                  delete updatedValues[d_arr[0]];

                  // Recalculez le total après suppression
                  const newTotal = Object.values(updatedValues).reduce((acc, curr) => acc + curr, 0);
                  setTotalCarRepairValue(newTotal);

                  return updatedValues;
                });
              } else {
                element.value = `${d_arr[1]}__--__${d_arr[2]}`;
                // Mise à jour de l'état carRepairValues
                setCarRepairValues((prevValues) => {
                  const updatedValues = {
                    ...prevValues,
                    [d_arr[0]]: data.response.val,
                  };

                  // Mise à jour de l'état totalCarRepairValue avec les valeurs actualisées
                  const newTotal = Object.values(updatedValues).reduce((acc, curr) => acc + curr, 0);
                  setTotalCarRepairValue(newTotal);

                  return updatedValues;
                });

                if (data.response.text !== '') {
                  onNewRepairText(id, data.response.text);
                }
              }
              validateRequiredFields();
            }
          } else {
            console.log('erreur!');
          }
        })
        .catch(() => {
          console.log('major error !');
        });
    }
  };
  const validateRequiredFields = () => {
    const invalidElements = requiredIds.filter((id) => {
      const elements = document.querySelectorAll(`#${id} path, #${id} circle`);
      const isInvalid = Array.from(elements).some((element) => {
        const fill = element?.style.fill;
        return !fill || fill === 'white';
      });
      if (isInvalid) {
        elements.forEach((element) => element?.setAttribute('style', 'stroke: red'));
        return true;
      }
      return false;
    });
    setInvalidIds(invalidElements);

    const isValid = invalidElements?.length === 0;
    onValidate(isValid);
  };

  useEffect(() => {
    validateRequiredFields(); // Pour avoir les stroke red sur les required
    const elements = document.querySelectorAll('#car-svg g[id], #car-svg svg[id]');

    const handleClick = (event) => {
      const elementId = event.currentTarget.id;
      const firstChild = event.currentTarget.querySelector('path, rect, ellipse, circle, polygon, polyline');

      ajSvgClick(elementId, (id, newRepairText) => {
        // Attendre que ajSvgClick ait fini ses modifications
        return new Promise((resolve) => {
          const fill = firstChild?.style.fill;
          resolve(fill);
        }).then((fill) => {
          setRepairTexts((prevRepairTexts) => ({
            ...prevRepairTexts,
            [id]: {
              text: newRepairText,
              fill: fill || 'none',
            },
          }));
        });
      });
    };

    elements.forEach((element) => {
      element.addEventListener('click', handleClick);
    });

    // Ajout d'un nettoyage pour retirer les listeners quand le composant est démonté
    return () => {
      elements.forEach((element) => {
        element.removeEventListener('click', handleClick);
      });
    };
  }, []);

  return (
    <>
      <Paper elevation={0} sx={{ backgroundColor: '#eeeeee' }}>
        <Grid container rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={5}>
            <Typography variant="body1" align="center">
              Niveau de gravité
            </Typography>
            <Box id="svg_colour_indexes">
              <Box className="svg_col_index" style={{ backgroundColor: 'white' }}></Box>
              <Box className="svg_col_index" style={{ backgroundColor: '#FFEECD' }}></Box>
              <Box className="svg_col_index" style={{ backgroundColor: '#F9c07a' }}></Box>
              <Box className="svg_col_index" style={{ backgroundColor: '#f97779' }}></Box>
              <Box className="svg_col_index" style={{ backgroundColor: '#979ed2' }}></Box>
            </Box>
            <CarSvg />
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            sx={{
              position: 'sticky',
              top: { xs: '2em', md: 0 }, // Pour coller en haut
              overflowY: 'auto',
              maxHeight: '80vh', // Prendre en compte la hauteur de la fenêtre de visualisation
            }}
          >
            {Object.values(repairTexts).map((repairInfo, index) => (
              <Typography align={isMobile ? 'center' : 'inherit'} variant="body2" key={index}>
                {formatString(repairInfo.text as string)}
              </Typography>
            ))}
            {Object.values(repairTexts)?.length < 1 && (
              <Typography align={isMobile ? 'center' : 'inherit'} variant="body1">
                Cliquez sur chaque élément pour vérifier l'état
              </Typography>
            )}
            <Typography
              align={isMobile ? 'center' : 'inherit'}
              variant="body1"
              sx={{ fontWeight: 'bold', marginTop: 5 }}
            >
              Total : {totalCarRepairValue} €
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Car;
